<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="$t('profile.inbox')" name="inbox">
        <inbox v-if="isCurrentTab('inbox')"></inbox>
      </el-tab-pane>
      <el-tab-pane :label="$t('profile.outbox')" name="message">
        <send-message v-if="isCurrentTab('message')"></send-message>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Inbox from '../inbox/message-list/index.vue'
import sendMessage from '../sendMessage/message-list/index.vue'

const activeName = ref('inbox')
const currentTab = ref('inbox')

const store = useStore()

const isCurrentTab = (tabName) => {
  return tabName === currentTab.value
}

const handleClick = (tab, event) => {
  currentTab.value = tab.paneName
}

store.dispatch('user/getUserList')
</script>
