<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfigRef"
      :pageTitle="$t('general.router-my-messages')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #message="scope">
        <div v-html="scope.row.content"></div>
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { searchFormConfig } from './config/message.search'
import { contentTableConfig } from './config/message.table'

const store = useStore()
// const pageInitialParams = {
//   remark: 'sender'
// }

// 表格基础配置项
const tableOptions = {
  moduleName: 'system', // 所属的模块
  pageName: 'sendMessage', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowEditBtn: false, // 是否显示添加按钮的插槽
  // pageInitialQuery: pageInitialParams,
  deleteRecordAction: 'system/deleteSentMessageById',
  pageAction: 'system/getSendMessageList', // 获取表格的Action
  pageCountGetter: 'system/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'system/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)

const searchFormConfigRef = computed(() => {
  const receiverListItem = searchFormConfig.formItems.find(
    (item) => item.field === 'receiver'
  )
  const userList = store.getters.allUserList || {}
  receiverListItem.tableList = userList.map((item) => {
    return { value: item.userName }
  })
  return searchFormConfig
})

// const formateData = (formData) => {
//   const formateObejct = {
//     // ...pageInitialParams,
//     ...formData,
//     startTime: formData.createTime ? formData.createTime[0] : '',
//     endTime: formData.createTime ? formData.createTime[1] : ''
//   }
//   delete formateObejct.createTime
//   return formateObejct
// }

const pageContentRef = ref('')
const pageSearchRef = ref('')

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}

const router = useRouter()

const handleViewData = (id) => {
  router.push(`/system/message/view/${id}`)
}

const handleNewData = () => {
  router.push('/system/message/new')
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
