import { messageStatusOption } from '@/utils/constant/const.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'sender',
      type: 'autocomplete',
      tableList: [],
      label: 'profile.sender'
    },
    {
      field: 'receiver',
      type: 'autocomplete',
      tableList: [],
      label: 'profile.receiver'
    },
    {
      field: 'title',
      type: 'input',
      label: 'profile.title'
    },
    // {
    //   field: 'content',
    //   type: 'input',
    //   label: 'profile.message'
    // },
    {
      field: 'status',
      type: 'select',
      options: messageStatusOption,
      label: 'common.app-status',
      otherOptions: {}
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
