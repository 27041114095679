// import { messageStatusOption } from '@/utils/constant/const.js'
// import { getOptionsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'userNames',
      label: 'profile.receiver'
    },
    {
      prop: 'title',
      label: 'profile.title'
    },
    // {
    //   prop: 'content',
    //   label: 'profile.message',
    //   slotName: 'message'
    // },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    // {
    //   prop: 'status',
    //   label: 'general.status',
    //   formatter: (row) => {
    //     return getOptionsItemLabel(row, messageStatusOption)
    //   }
    // },
    {
      label: 'common.app-operation',
      width: '250',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
