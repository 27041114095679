<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfigRef"
      :pageTitle="$t('general.router-my-messages')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #message="scope">
        <div v-html="scope.row.content"></div>
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { searchFormConfig } from './config/message.search'
import { contentTableConfig } from './config/message.table'

const store = useStore()

// const pageInitialParams = {
//   remark: 'receiver'
// }
// 表格基础配置项
const tableOptions = {
  moduleName: 'system', // 所属的模块
  pageName: 'receiveMessage', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: false, // 是否显示添加按钮的插槽
  isShowEditBtn: false,
  // isShowDeleteBtn: false,
  // pageInitialQuery: pageInitialParams,
  deleteRecordAction: 'system/deleteReceiveMessageById',
  pageAction: 'system/getReceiveMessageList', // 获取表格的Action
  pageCountGetter: 'system/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'system/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)

const searchFormConfigRef = computed(() => {
  const senderListItem = searchFormConfig.formItems.find(
    (item) => item.field === 'sender'
  )
  const userList = store.getters.allUserList || {}

  senderListItem.tableList = userList.map((item) => {
    return { value: item.userName }
  })
  const receiverListItem = searchFormConfig.formItems.find(
    (item) => item.field === 'receiver'
  )
  receiverListItem.tableList = userList.map((item) => {
    return { value: item.userName }
  })
  return searchFormConfig
})

const pageContentRef = ref('')
const pageSearchRef = ref('')

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}

const router = useRouter()

const handleViewData = (id) => {
  router.push(`/system/message/readmessages/${id}`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
